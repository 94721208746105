import Vue from "vue";
import VueRouter from "vue-router";
import LoadingView from "../views/LoadingView.vue";

Vue.use(VueRouter);

const routes = [
 
  {
    path: "/",
    name: "load",
    component: LoadingView,
    meta: {
      title: "office",
    },
  },
  {
    path: "/home/:url",
    name: "home",
    meta: {
      title: "office",
    },
    component: () => import("../views/HomeView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

//解决重复路由问题  路由多次点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
